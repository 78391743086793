<template src="./index.html">
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import { jump } from '../../util/jumpPage'
import iframeObj from '../../util/iframe'

export default {
  async asyncData ({store}) {
    const DISPATCH = store.dispatch
		let currSpId = store.state.route.params.id || 0

		//first to load
		let partnerDetail = await DISPATCH('dataPartner/getPartnerDetail',{
				SpUin: currSpId,
				SpSubAccountUin: currSpId
		})
  },
	computed: {
    ...mapState('dataPartner', {
			partnerDetail: s => s.detail
		})
  },
	mounted(){
		this.partnerDetail.productDomain && iframeObj.setIframeContent('iframebox', this.partnerDetail.productDomain)
		document.title = '公司详情'
	}
}
</script>
<style>
html body{
	min-width: auto !important;
}
</style>
