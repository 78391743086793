//在iframe显示富文本内容

import xss from 'xss'

const receiveMessage = evt => {
	if (evt.origin !== 'https://iot.cloud.tencent.com' && evt.origin !== 'http://iot.cloud.tencent.com') {
		return
	}
	let data = evt.data
	iframeObj[data.action] && iframeObj[data.action](data)
}

const iframeObj = {
	setIframeContent (iframebox, htmlVal) {
		let iframe = document.createElement('iframe')

		iframe.style.border = 0
		iframe.style.width = '100%'
		iframe.style.opacity = 0
		iframe.id = Date.now()
		document.getElementById(iframebox).appendChild(iframe)

		iframe.onload = () => {
			htmlVal = xss(htmlVal, {
				onIgnoreTagAttr: function (tag, name, value, isWhiteAttr) {
					if (name === 'class' && (value == 'ql-align-left' || value == 'ql-align-center' || value == 'ql-align-right')) {
						return 'class="' + value + '"'
					} else if (name === 'style' && /^color\: rgb\(\d+\, \d+\, \d+\)\;$/.test(value)) {
						return 'style="' + value + '"'
					}
				}
			})
			iframe.contentWindow.postMessage(
				{
					iframeId: iframe.id,
					html: htmlVal,
				},
				'*'
			)
		}

		iframe.src = 'https://iot.cloud.tencent.com/proxy/domain/qzonestyle.gtimg.cn/qcloud/marketiot/iframe/index.html'//https://qzonestyle.gtimg.cn/qcloud/marketiot/iframe/index.html

		if (window.addEventListener) {
			window.addEventListener('message', receiveMessage, false)
		} else {
			window.attachEvent('onmessage', receiveMessage)
		}
	},
	resizeWindow ({ iframeId, widthHeight }) {
		let iframe = document.getElementById(iframeId)
		iframe.style.height = widthHeight.height + 'px'
		iframe.style.overflow = 'hidden'
		iframe.style.opacity = 1
	},
}

export default iframeObj
